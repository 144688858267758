import '../styles/Message.css';
import { useState } from 'react';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import ReactPlayer from 'react-player'
import TextareaAutosize from 'react-textarea-autosize';
import { decodeTime } from 'ulid';
import { ReactComponent as EditIcon } from '../assets/edit.svg';
import { ReactComponent as DeleteIcon } from '../assets/delete.svg';
import getIsTouchOnlyDevice from '../utils/getIsTouchOnlyDevice';
import query from '../utils/query';
import getRegex from './../utils/getRegex';
import { useUser } from '../contexts/UserProvider';
import { useModal } from '../contexts/ModalProvider';
import useSocketEvent from '../hooks/useSocketEvent';
import Markdown from './Markdown';
import Warning from './Warning';
import Tooltip from './Tooltip';
import UserDisplay from './UserDisplay';

function Message({ message, isNewSection, isPreview }) {
  const user = useUser();
  const { openModal } = useModal();
  const queryClient = useQueryClient();
  const { id, user: author, content, files, nonce, isUnsent } = message;
  const [filesSizeLoaded, setFilesSizeLoaded] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [textEdit, setTextEdit] = useState(content);
  const isTouchOnlyDevice = getIsTouchOnlyDevice();
  const isAuthor = user.id === author.id;

  useSocketEvent('receive-upload-progress', async uploadProgress => {
    if (nonce !== uploadProgress.nonce) return;
    const newFilesSizeLoaded = uploadProgress.filesSizeLoaded;
    setFilesSizeLoaded(newFilesSizeLoaded);
  });

  useSocketEvent('edit-message', async editedMessage => {
    if (id !== editedMessage.id) return;
    updateEditedMessages(editedMessage);
  });

  useSocketEvent('delete-message', async deletedMessage => {
    if (id !== deletedMessage.id) return;
    updateDeletedMessages();
  });

  const mutationPath = `/channels/${message.channel_id}/messages/${id}`;

  const messagesPatchMutation = useMutation({
    mutationFn: message => query(mutationPath, {
      method: 'PATCH', body: { message }
    })
  });

  const messagesDeleteMutation = useMutation({
    mutationFn: () => query(mutationPath, {
      method: 'DELETE'
    })
  });

  const timestamp = decodeTime(id ?? nonce);
  const date = new Date(timestamp).toLocaleString();
  const text = <Markdown text={content} />;

  const imageURLRegex = getRegex('imageURL');
  const globalImageURLRegex = new RegExp(imageURLRegex, 'g');
  const imageURLs = content.match(globalImageURLRegex);

  const images = imageURLs?.map((imageURL, index) => {
    return <div
      className='message__image-container'
      key={index}
    >
      <img
        className='message__image'
        src={imageURL}
        loading='lazy'
        alt="&nbsp;We couldn't load this image..."
        onError={handleImgError}
      />
    </div>
  });

  const videoURLRegex = getRegex('youTubeURL');
  const globalVideoURLRegex = new RegExp(videoURLRegex, 'g');
  const videoURLMatches = content.match(globalVideoURLRegex);

  const videos = videoURLMatches?.map((videoURLMatch, index) => {
    const videoId = videoURLMatch.match(videoURLRegex)?.[1] ?? 'dQw4w9WgXcQ';
    const videoURL = `https://www.youtube.com/watch?v=${videoId}`;

    return <div
      key={index}
      className='message__video-container'
    >
      <ReactPlayer
        className='message__video'
        url={videoURL}
        light={true}
        playing={true}
        controls={true}
        width='100%'
        height='100%'
      />
    </div>
  });

  const songURLRegex = getRegex('spotifyURL');
  const globalSongURLRegex = new RegExp(songURLRegex, 'g');
  const songURLMatches = content.match(globalSongURLRegex);

  const songs = songURLMatches?.map((songURLMatch, index) => {
    const songURL = songURLMatch.replace('spotify.com', 'spotify.com/embed');

    return <div
      className='message__song-container'
      key={index}
    >
      <div className='message__song-loading'>
        <Warning type='loading' />
      </div>
      <iframe
        className='message__song'
        title='Spotify Track'
        width='100%'
        height='100%'
        src={songURL}
        loading='lazy'
        allow="accelerometer; autoplay; clipboard-write;
        encrypted-media; fullscreen; gyroscope; picture-in-picture"
      >
      </iframe>
    </div>
  });

  const imageFiles = files.filter(file => file.url?.match(/\.(jpg|jpeg|gif|png|webp|jfif)$/i));
  const imageEmbeds = (isUnsent && files.length)
    ? <div className='message__image-container'>
      <div className='message__image-loader'>
        <div className='message__image-loader-label'>
          <span>
            Processing {files.length} File
            {(files.length === 1) ? '' : 's'}...
          </span>
          <span
            className='message__image-loader-percentage'
            style={{ color: `hsl(${0 + filesSizeLoaded}, 100%, 65%)` }}
          >
            {(filesSizeLoaded === 100) ? 'Rendering' : `${filesSizeLoaded}%`}
          </span>
        </div>
        <div className='message__image-loader-bar'>
          <div
            className='message__image-loader-progress'
            style={{ width: `${filesSizeLoaded}%` }}
          >
          </div>
        </div>
      </div>
    </div>
    : imageFiles?.map((file, index) => {
      return <div
        className='message__image-container'
        key={index}
      >
        <img
          className='message__image'
          src={file.url}
          loading='lazy'
          alt="&nbsp;We couldn't load this image..."
          onError={handleImgError}
        />
      </div>
    });

  function handleImgError(event) {
    event.target.src = 'https://cdn.chatpex.com/assets/file-not-found.png';
  }

  function handleEdit() {
    setIsEditing(true);
  }

  function handleDelete(event) {
    if (event.shiftKey) return deleteMessage();
    openModal('DELETE_MESSAGE', { message, deleteMessage });
  }

  function handleEditChange(event) {
    setTextEdit(event.target.value);
  }

  function handleEditSave() {
    saveMessageEdit();
  }

  function handleEditCancel() {
    cancelMessageEdit();
  }

  function handleTextareaKeyDown(event) {
    event.stopPropagation();

    if (event.key === 'Escape') {
      event.preventDefault();
      return cancelMessageEdit();
    }

    if (event.key === 'Enter' && !event.shiftKey && !isTouchOnlyDevice) {
      event.preventDefault();
      return saveMessageEdit();
    }
  }

  function saveMessageEdit() {
    if (!textEdit.length) return openModal('DELETE_MESSAGE', {
      message, deleteMessage
    });

    const editedMessage = { content: textEdit };
    messagesPatchMutation.mutate(editedMessage);

    setIsEditing(false);
  }

  function cancelMessageEdit() {
    setIsEditing(false);
    setTextEdit(content);
  }

  function deleteMessage() {
    messagesDeleteMutation.mutate();
  }

  function updateEditedMessages(editedMessage) {
    queryClient.setQueryData(
      ['messages', message.channel_id],
      oldMessages => oldMessages.map(m => m.id === id ? editedMessage : m)
    );
  };

  function updateDeletedMessages() {
    queryClient.setQueryData(
      ['messages', message.channel_id],
      oldMessages => oldMessages.filter(m => m.id !== message.id)
    );
  };

  return <div
    className='message'
    style={{ borderColor: author.color }}
  >
    {(!isPreview && isAuthor) && <div className='message__buttons'>
      <Tooltip content='Edit'>
        <button onClick={handleEdit}><EditIcon /></button>
      </Tooltip>
      <Tooltip content='Delete'>
        <button onClick={handleDelete}><DeleteIcon /></button>
      </Tooltip>
    </div>}
    {(isNewSection || isPreview) && <div className='message__user'>
      <UserDisplay user={author} bottom={date} />
    </div>}
    <div className='message__content'>
      <div
        className='message__unsent'
        style={{ opacity: isUnsent ? 0.5 : null }}
      >
        {isEditing
          ? <div className='message__editing'>
            <TextareaAutosize
              className='message__editing-textarea'
              placeholder='Edit message content...'
              maxRows='15'
              maxLength='5000'
              value={textEdit}
              autoFocus={true}
              onChange={handleEditChange}
              onKeyDown={handleTextareaKeyDown}
            />
            <div className='message__editing-buttons'>
              <button onClick={handleEditSave}>Save</button>
              &nbsp;or&nbsp;
              <button onClick={handleEditCancel}>cancel</button>
            </div>
          </div>
          : text}
        {images}
        {videos}
        {songs}
      </div>
      {imageEmbeds}
    </div>
  </div>
}

export default Message;