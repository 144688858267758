import '../styles/FormInput.css';
import { useState, useId } from 'react';
import { ReactComponent as VisibilityOnIcon } from '../assets/visibility-on.svg';
import { ReactComponent as VisibilityOffIcon } from '../assets/visibility-off.svg';
import Input from './Input';
import Tooltip from './Tooltip';

function FormInput({ label, description, icon, placeholder,
    maxLength, autoFocus, isCompact, isHidden, value, onChange }) {
    const [isVisible, setIsVisible] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const inputId = useId();

    function handleEyeClick() {
        setIsVisible(oldIsVisible => !oldIsVisible);
    }

    function handleFocus() {
        setIsFocused(true);
    }

    function handleBlur() {
        setIsFocused(false);
    }

    let classNames = 'form-input';
    if (isCompact) classNames += ' form-input--compact';

    return <div className={classNames}>
        <div className='form-input__text'>
            <label
                className='form-input__label'
                htmlFor={inputId}
            >
                {label}
            </label>
            <div
                className='form-input__description'
                style={{ opacity: isFocused ? null : 0 }}
            >
                {description || <span>&nbsp;</span>}
            </div>
        </div>
        <div className='form-input__body'>
            <div className='form-input__icon'>
                {icon}
            </div>
            <Input
                id={inputId}
                style={{
                    paddingInline: '56px',
                    paddingRight: isHidden ? null : '12px',
                }}
                type={(isVisible || !isHidden) ? 'text' : 'password'}
                placeholder={placeholder}
                maxLength={maxLength}
                autoFocus={autoFocus}
                autoComplete='on'
                value={value}
                onChange={onChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
            />
            {isHidden && <Tooltip
                content='Toggle Visibility'
                offset={[0, 6]}
            >
                <div
                    className='form-input__eye'
                    onClick={handleEyeClick}
                >
                    {isVisible ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
                </div>
            </Tooltip>}
        </div>
    </div>
}

export default FormInput;