import '../styles/Modal.css';
import { ReactComponent as CloseIcon } from '../assets/close.svg';
import { useModal } from '../contexts/ModalProvider';
import AddCommunity from './AddCommunity';
import CreateInvite from './CreateInvite';
import InvalidFileSize from './InvalidFileSize';
import LogOut from './LogOut';
import DeleteMessage from './DeleteMessage';
import Button from './Button';

function Modal() {
    const { modalId, isModalClosed, closeModal, modalPage } = useModal();

    const modals = {
        'ADD_COMMUNITY': {
            name: 'Add Community',
            content: [<AddCommunity key='1' />]
        },
        'CREATE_INVITE': {
            name: 'Create Invite',
            content: [<CreateInvite key='1' />]
        },
        'INVALID_FILE_SIZE': {
            name: 'Invalid File Size',
            content: [<InvalidFileSize key='1' />]
        },
        'LOG_OUT': {
            name: 'Log Out',
            content: [<LogOut key='1' />]
        },
        'DELETE_MESSAGE': {
            name: 'Delete Message',
            content: [<DeleteMessage key='1' />]
        }
    };

    const { name, content } = modals[modalId] || {};
    const pageContent = content?.[modalPage];

    function handleClose() {
        closeModal();
    }

    let classNames = 'modal';
    if (isModalClosed) classNames += ' modal--closed';

    return <div className={classNames}>
        <div
            className='modal__backdrop'
            onClick={handleClose}
            role='presentation'
        >
        </div>
        <div className='modal__dialog'>
            <div className='modal__header'>
                {name}
                <Button
                    content={<CloseIcon />}
                    tooltip='Close'
                    onClick={handleClose}
                />
            </div>
            <div className='modal__content'>
                {pageContent}
            </div>
        </div>
    </div>
}

export default Modal;