import '../styles/Warning.css';
import { motion } from 'framer-motion';
import { ReactComponent as ErrorIcon } from '../assets/error.svg';
import { ReactComponent as LoadingIcon } from '../assets/loading.svg';

function Warning({ type, icon, color = 'var(--primary-color)', text }) {
    const warnings = {
        'error': { icon: <ErrorIcon />, color: 'var(--error-color)' },
        'loading': { icon: <LoadingIcon />, color: 'var(--primary-color)' }
    };

    const warning = warnings[type] ?? { icon, color };
    warning.text = text;

    return <motion.div
        className='warning'
        style={{ color: warning.color }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.4 }}
    >
        {warning.icon}
        {warning.text}
    </motion.div>
}

export default Warning;