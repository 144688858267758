import '../styles/Recipe.css';
import { motion } from 'framer-motion';
import Block from './Block';

function Recipe() {
    return <motion.div
        className='recipe'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.4 }}
    >
        <div className='recipe__image'>
            <h1 className='recipe__ooga'>
                Oogat Booga
            </h1>
        </div>
        <Block label='Ingredients'>
            <ul className='recipe__ingredients'>
                <li>6 eggs</li>
                <li>6 dl sugar</li>
                <li>6 dl wheat flour</li>
                <li>15 tablespoons cocoa powder</li>
                <li>3 tablespoons vanilla sugar</li>
                <li>300 grams butter</li>
                <li>100 grams cooking chocolate</li>
            </ul>
        </Block>
        <Block label='Preparation'>
            <ol className='recipe__preparation'>
                <li>Melt the butter. Now. It needs time to cool, fool.</li>
                <li>Whisk the eggs and sugar together into an egg mixture.</li>
                <li>Mix all the dry ingredients in layers.</li>
                <li>Add the dry ingredients to the egg mixture and mix.</li>
                <li>Pour the butter in as well. Hello, cholesterol!</li>
                <li>Break the cooking chocolate into small pieces.</li>
                <li>Finally, mix the chocolate pieces into the batter.</li>
                <li>Now, grease the long pan and pour the batter into it.</li>
                <li>Put it in the oven at 200&#176; for 15-20 minutes, and wait.</li>
            </ol>
        </Block>
        <Block label='Finished'>
            <p className='recipe__finished'>
                The cake is ready when the top is slightly crispy
                and the center is slightly doughy. And that's how you
                bake <span>verdens beste sjokoladekake</span>.
                Enjoy! Oh, the cake in the picture isn't
                actually the cake you just made, if you didn't
                realize that before now.
            </p>
        </Block>
    </motion.div>
}

export default Recipe;