import '../styles/LogIn.css';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import { ReactComponent as EmailIcon } from '../assets/email.svg';
import { ReactComponent as LockIcon } from '../assets/lock.svg';
import { useUser } from '../contexts/UserProvider';
import useGreeting from '../hooks/useGreeting';
import query from '../utils/query';
import FormInput from './FormInput';
import ErrorMessage from './ErrorMessage';
import Button from './Button';

function LogIn() {
    const { logIn } = useUser();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const greeting = useGreeting();

    const loginMutation = useMutation({
        mutationFn: login => query('/auth/login', {
            method: 'POST', body: { login }
        }),
        onSuccess: async data => {
            await logIn(data.token);
            setIsLoading(false);
        },
        onError: error => {
            setError(error.message);
            setIsLoading(false);
        }
    });

    function handleLogIn(event) {
        event.preventDefault();
        setError(null);
        setIsLoading(true);
        const login = { email, password };
        loginMutation.mutate(login);
    }

    function handleEmailChange(event) {
        setEmail(event.target.value);
    }

    function handlePasswordChange(event) {
        setPassword(event.target.value);
    }

    function handleSignUpInstead() {
        navigate('/signup');
    }

    return <motion.div
        className='login'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.4 }}
    >
        <div className='login__banner'></div>
        <form
            className='login__form'
            onSubmit={handleLogIn}
        >
            <div className='login__title'>
                {greeting}, and welcome back!
            </div>
            <div className='login__inputs'>
                <FormInput
                    label='Email'
                    placeholder='Enter your email address...'
                    icon={<EmailIcon />}
                    autoFocus={true}
                    value={email}
                    onChange={handleEmailChange}
                />
                <FormInput
                    label='Password'
                    placeholder='...and now, your password.'
                    icon={<LockIcon />}
                    maxLength='30'
                    isHidden={true}
                    value={password}
                    onChange={handlePasswordChange}
                />
                <ErrorMessage error={error} />
            </div>
            <div className='login__buttons'>
                <Button
                    content='LOG IN'
                    type='submit'
                    isLoading={isLoading}
                />
                <Button
                    content='OR SIGN UP INSTEAD'
                    model='secondary'
                    onClick={handleSignUpInstead}
                />
            </div>
        </form>
    </motion.div>
}

export default LogIn;