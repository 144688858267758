import '../styles/CommunityMembersPresence.css';
import UserDisplay from './UserDisplay';

function CommunityMembersPresence({ label, members }) {
  const collator = new Intl.Collator('en', {
    numeric: true, sensitivity: 'base'
  });

  members.sort((a, b) => (
    collator.compare(a.user.name, b.user.name)
  ));

  return !!members.length && <details
    className='community-members-presence'
    open={true}
  >
    <summary className='community-members-presence__summary'>
      {label} <span>{members.length.toLocaleString()}</span>
    </summary>
    <ul className='community-members-presence__members'>
      {members.map(member => {
        const { user } = member;

        return <li
          key={user.id}
          className='community-members-presence__member'
        >
          <UserDisplay user={user} />
        </li>
      })}
    </ul>
  </details>
}

export default CommunityMembersPresence;