import '../styles/CommunitiesArea.css';
import stc from 'string-to-color';
import { ReactComponent as AddCommunityIcon } from '../assets/add-community.svg';
import { ReactComponent as RobloxIcon } from '../assets/roblox.svg';
import { ReactComponent as FinnIcon } from '../assets/finn.svg';
import { useUser } from '../contexts/UserProvider';
import { useModal } from '../contexts/ModalProvider';
import useSectionTypes from '../hooks/useSectionTypes';
import Button from './Button';
import Tooltip from './Tooltip';

function CommunitiesArea() {
    const user = useUser();
    const { openModal } = useModal();
    const { sectionTypes } = useSectionTypes();

    const { communities, selectedCommunity } = user;
    const isASectionSelected = user.selectedSectionId !== null;

    const isPeltel = user.id === '01H0G6NVWK7C9MVZTPHB6TVFY9';
    const isChilsi = user.id === '01H0CHRY5VHJBY5FH126F509YA';

    function handleSectionChange(sectionId) {
        user.setSelectedSection(sectionId);
    }

    function handleCommunityChange(newSelectedCommunityId) {
        user.setSelectedCommunity(newSelectedCommunityId);
    }

    function handleOpenRoblox() {
        window.open('https://www.roblox.com', '_blank');
    }

    function handleOpenFinn() {
        window.open('https://www.finn.no/realestate/homes/search.html?location=0.20061&price_to=3300000&property_type=3&property_type=1&property_type=2&property_type=4&property_type=18&sort=PUBLISHED_DESC', '_blank');
    }

    function handleAddCommunity() {
        openModal('ADD_COMMUNITY');
    }

    return <div className='communities-area'>
        <div className='communities-area__buttons'>
            {sectionTypes.map((sectionType, index) => {
                const { icon, name, disabled } = sectionType;
                const isSelectedSection = user.selectedSectionId === index;

                return <Button
                    key={name}
                    content={icon}
                    tooltip={name}
                    placement='right'
                    isSelected={isSelectedSection}
                    disabled={disabled}
                    onClick={() => handleSectionChange(index)}
                />
            })}
        </div>
        <div className='communities-area__list'>
            {communities?.map(community => {
                const { id, name } = community;
                const isSelectedCommunity = !isASectionSelected
                    && id === selectedCommunity.id;
                const color1 = stc(id);
                const color2 = stc(id.split('').reverse().join(''));
                const initials = name.slice(0, 2);

                const communityStyle = {
                    backgroundColor: color1,
                    backgroundImage: isSelectedCommunity
                        && `linear-gradient(${color1} 50%, ${color2})`,
                    borderRadius: isSelectedCommunity && '50%'
                };

                return <Tooltip
                    key={id}
                    content={name}
                    placement='right'
                >
                    <button
                        className='communities-area__community'
                        onClick={() => handleCommunityChange(id)}
                        style={communityStyle}
                    >
                        {initials}
                    </button>
                </Tooltip>
            })}
        </div>
        <div className='communities-area__add-community'>
            {isPeltel && <Button
                content={<RobloxIcon />}
                tooltip='Open Roblox'
                placement='right'
                onClick={handleOpenRoblox}
            />}
            {isChilsi && <Button
                content={<FinnIcon />}
                tooltip='Open Finn'
                placement='right'
                onClick={handleOpenFinn}
            />}
            <Button
                content={<AddCommunityIcon />}
                tooltip='Add Community'
                placement='right'
                onClick={handleAddCommunity}
            />
        </div>
    </div>
}

export default CommunitiesArea;