const getRegex = type => {
    const regexes = {
        username: /^[A-Z0-9\s_]+$/i,
        hexColor: /^#([A-F0-9]{0,6})$/i,
        anyURL: /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
        imageURL: /(http(s?):)([/|.|\w|-])*\.(?:jpg|jpeg|gif|png|webp|jfif)/,
        youTubeURL: /(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|live\/|shorts\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?/,
        spotifyURL: /(?:spotify:|https:\/\/[a-z]+\.spotify\.com\/(show\/|playlist\/|track\/|episode\/|user\/(.*)\/playlist\/))(.*)/
    };
    const regex = regexes[type];

    return regex;
}

export default getRegex;