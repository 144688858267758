import { createContext, useContext, useState } from 'react';

const ModalContext = createContext();

export function useModal() {
    return useContext(ModalContext);
}

function ModalProvider({ children }) {
    const [modalId, setModalId] = useState();
    const [modalPage, setModalPage] = useState(0);
    const [modalData, setModalData] = useState();
    const [isModalClosed, setIsModalClosed] = useState(true);

    function openModal(newModalId, newModalData) {
        setModalId(newModalId);
        setModalData(newModalData);
        setIsModalClosed(false);
    }

    function closeModal() {
        setIsModalClosed(true);
    }

    function goToNextModalPage() {
        setModalPage(oldModalPage => oldModalPage + 1);
    }

    function goToPreviousModalPage() {
        setModalPage(oldModalPage => oldModalPage - 1);
    }

    const modal = {
        modalId, modalPage, modalData,
        isModalClosed, openModal, closeModal,
        goToNextModalPage, goToPreviousModalPage
    };

    return <ModalContext.Provider value={modal}>
        {children}
    </ModalContext.Provider>
}

export default ModalProvider;