import '../styles/ColorPicker.css';
import { HexColorPicker } from 'react-colorful';
import { ReactComponent as PaletteIcon } from '../assets/palette.svg';
import getRegex from './../utils/getRegex';
import FormInput from './FormInput';

function ColorPicker({ label, color, onChange }) {
    const colorRegex = getRegex('hexColor');
    const isValidColorLength = color.length === 4 || color.length === 7;
    const displayColor = isValidColorLength ? color : color.padEnd(7, 0);

    function handleColorInputChange(event) {
        let newColor = event.target.value.toUpperCase();
        if (!newColor.startsWith('#')) newColor = '#' + newColor;
        if (!newColor?.match(colorRegex)) return;
        onChange(newColor);
    }

    function handleColorPickerChange(newColor) {
        newColor = newColor.toUpperCase();
        onChange(newColor);
    }

    return <div className='color-picker'>
        <FormInput
            label={label}
            description='The color should be presented as a hex code.'
            placeholder='#FF69FF'
            icon={<PaletteIcon />}
            maxLength='7'
            isCompact={true}
            value={color}
            onChange={handleColorInputChange}
        />
        <div className='color-picker__display'>
            <div
                className='color-picker__preview'
                style={{ backgroundColor: displayColor }}
            >
            </div>
            <HexColorPicker
                color={displayColor}
                onChange={handleColorPickerChange}
            />
        </div>
    </div>
}

export default ColorPicker;