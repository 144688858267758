import '../styles/CustomArea.css';
import { useUser } from '../contexts/UserProvider';
import CommunityMembers from './CommunityMembers';

function CustomArea() {
  const { selectedSectionId } = useUser();

  const isASectionSelected = selectedSectionId !== null;

  const component = isASectionSelected
    ? <div className='custom-area__default'></div>
    : <div className='custom-area__community'>
      <div className='custom-area__bar'>
        &nbsp;
      </div>
      <CommunityMembers />
    </div>

  return <div className='custom-area'>
    {component}
  </div>
}

export default CustomArea;