import '../styles/Theme.css';
import { useState } from 'react';
import { useTheme } from '../contexts/ThemeProvider';
import Tag from './Tag';
import ColorPicker from './ColorPicker';
import Button from './Button';

function Theme({ primaryColor, secondaryColor,
    setPrimaryColor, setSecondaryColor }) {
    const [selectedTheme, setSelectedTheme] = useState(null);
    const { primaryDefaultColor, secondaryDefaultColor } = useTheme();

    const themes = [
        {
            name: 'Chatpex Default',
            colors: [primaryDefaultColor, secondaryDefaultColor]
        },
        { name: 'Washed Away', colors: ['#589ABE', '#2F2F6A'] },
        { name: 'Night At The Office', colors: ['#4E7990', '#251733'] },
        { name: 'Retro Night', colors: ['#D88FFF', '#4F296D'] },
        { name: 'Retro Night Pro', colors: ['#1FA5B4', '#4F296D'] },
        { name: 'Tax The Queen', colors: ['#C7D3D4', '#603F83'] },
        { name: 'The Science Behind Magic', colors: ['#74D478', '#4056FF'] },
        { name: 'Land Of Milk And Honey', colors: ['#FFFFFF', '#0038B8'] },
        { name: 'Cotton Candy', colors: ['#FF88F1', '#22A2D1'] },
        { name: 'Peace In Heaven', colors: ['#B6DFEE', '#40C0FF'] },
        { name: 'Mighty Pegasus', colors: ['#00B1CC', '#DCFBFF'] },
        { name: 'Money Grows On Trees', colors: ['#FFE74D', '#264E3B'] },
        { name: 'Mother Nature', colors: ['#2E9768', '#403030'] },
        { name: 'Above And Below', colors: ['#45C3D4', '#632C43'] },
        { name: 'Sweet Chocolate', colors: ['#EF66CD', '#704242'] },
        { name: 'Watermelon Fields', colors: ['#77D474', '#DB5454'] },
        { name: 'Heart Of Love', colors: ['#FF9AFF', '#BC2424'] },
        { name: 'Hellbound', colors: ['#A30505', '#FF4B00'] },
        { name: 'Pony Park', colors: ['#845050', '#CFC2BC'] },
        { name: 'Pink Salt', colors: ['#6E6E6D', '#FAD0C9'] },
        { name: 'Tropical Beach', colors: ['#00BEAE', '#CDB599'] },
        { name: 'Caramel Coffee', colors: ['#A07855', '#D4B996'] },
        { name: 'Spot The Fly', colors: ['#1ED760', '#080A08'] },
        { name: 'Inside The Matrix', colors: ['#00FF8C', '#000000'] },
    ];

    function handlePrimaryColorChange(color) {
        setPrimaryColor(color);
        setSelectedTheme(null);
    }

    function handleSecondaryColorChange(color) {
        setSecondaryColor(color);
        setSelectedTheme(null);
    }

    function handleThemeClick(theme) {
        setPrimaryColor(theme.colors[0]);
        setSecondaryColor(theme.colors[1]);
        setSelectedTheme(theme);
    }

    return <div className='theme'>
        <label className='theme__label'>
            <span>Custom Theme <Tag text='Beta' /></span>
            <span className='theme__description'>
                Choose your own custom theme for the app.
                Use the color pickers or choose one of the presets below.
            </span>
        </label>
        <div className='theme__content'>
            <div className='theme__color-pickers'>
                <ColorPicker
                    label='Primary Color'
                    color={primaryColor}
                    onChange={handlePrimaryColorChange}
                />
                <ColorPicker
                    label='Secondary Color'
                    color={secondaryColor}
                    onChange={handleSecondaryColorChange}
                />
            </div>
            <div className='theme__list'>
                <label className='theme__list-label'>
                    Theme Presets
                    <span className='theme__list-description'>
                        Don't feel like picking colors
                        yourself? Choose a preset.
                    </span>
                </label>
                <div className='theme__buttons'>
                    {themes.map(theme => {
                        const { name, colors } = theme;
                        const isSelected = name === selectedTheme?.name;

                        return <Button
                            key={name}
                            content={name}
                            styles={{
                                button: {
                                    '--primary-color': colors[0],
                                    '--primary-button-color': colors[1],
                                    'outline': isSelected
                                        ? '2px solid white'
                                        : null,
                                }
                            }}
                            isSelected={isSelected}
                            onClick={() => handleThemeClick(theme)}
                        />
                    })}
                </div>
            </div>
            <div className='theme__list'>
                <div className='theme__list-header'>
                    <label className='theme__list-label'>
                        <span> Your Themes <Tag text='Coming' /></span>
                        <span className='theme__list-description'>
                            Create a unique new theme
                            and save it here for later use.
                        </span>
                    </label>
                    <Button
                        content='Save Theme'
                        disabled={true}
                    />
                </div>
                <div className='theme__buttons'>
                    <Button
                        content='My Cool Theme'
                        styles={{
                            button: {
                                '--primary-color': '#A0C1D1',
                                '--primary-button-color': '#235C33'
                            }
                        }}
                        disabled={true}
                    />
                </div>
            </div>
        </div>
    </div>
}

export default Theme;
