import '../styles/NavigationArea.css';
import { ReactComponent as AddChannelIcon } from '../assets/add-channel.svg';
import { ReactComponent as InviteIcon } from '../assets/invite.svg';
import { useUser } from '../contexts/UserProvider';
import { useModal } from '../contexts/ModalProvider';
import useSectionTypes from '../hooks/useSectionTypes';
import useChannelTypes from '../hooks/useChannelTypes';
import Button from './Button';
import Tooltip from './Tooltip';
import Warning from './Warning';
import Userbar from './Userbar';

function NavigationArea({ setSelectedPanel }) {
    const user = useUser();
    const { selectedSectionType } = useSectionTypes();
    const { channelTypes } = useChannelTypes();
    const { openModal } = useModal();

    const { selectedCommunity, channels, selectedChannel } = user;
    const isASectionSelected = user.selectedSectionId !== null;

    function handleTabChange(type) {
        user.setSelectedTabId(type);
        setSelectedPanel(1);
    }

    function handleChannelChange(newSelectedChannelId) {
        user.setSelectedChannel(newSelectedChannelId);
        setSelectedPanel(1);
    }

    function handleCreateInvite() {
        openModal('CREATE_INVITE');
    }

    const tabsComponent = isASectionSelected && <>
        <div className='navigation-area__section-name'>
            <span>{selectedSectionType.name}</span>
        </div>
        <div className='navigation-area__tab-list'>
            {selectedSectionType.tabs.map((tab, index) => {
                const { name, icon, disabled } = tab;
                const isSelectedTab = user.selectedTabId === index;

                return <Button
                    key={name}
                    content={<>
                        {icon}
                        {name}
                    </>}
                    model='secondary'
                    isSelected={isSelectedTab}
                    disabled={disabled}
                    onClick={() => handleTabChange(index)}
                />
            })}
        </div>
    </>

    const channelsComponent = !isASectionSelected && <>
        <div className='navigation-area__community-name'>
            <span>{selectedCommunity.name}</span>
        </div>
        <div className='navigation-area__community-buttons'>
            <Button
                content={<AddChannelIcon />}
                tooltip='Add Channel'
                disabled={true}
            />
            <Button
                content={<InviteIcon />}
                tooltip='Create Invite'
                onClick={handleCreateInvite}
            />
        </div>
        <div className='navigation-area__channel-list'>
            {channels?.map(channel => {
                const { id, name, type } = channel;
                const { icon, tooltip } = channelTypes[type];
                const isSelectedChannel = id === selectedChannel.id;

                return <Button
                    key={id}
                    content={<>
                        <Tooltip content={tooltip}>
                            {icon}
                        </Tooltip>
                        {name}
                    </>}
                    model='secondary'
                    isSelected={isSelectedChannel}
                    onClick={() => handleChannelChange(id)}
                />
            }) || <Warning type='loading' />}
        </div>
    </>

    return <div className='navigation-area'>
        {tabsComponent || channelsComponent}
        <div className='navigation-area__userbar'>
            <Userbar />
        </div>
    </div>
}

export default NavigationArea;