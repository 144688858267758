import '../styles/AddCommunity.css';
import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useUser } from '../contexts/UserProvider';
import { useModal } from './../contexts/ModalProvider';
import query from '../utils/query';
import Input from './Input';
import Button from './Button';

function AddCommunity() {
    const user = useUser();
    const { closeModal } = useModal();
    const [joinText, setJoinText] = useState('');
    const [createText, setCreateText] = useState('');

    const communitiesMutation = useMutation({
        mutationFn: community => query('/communities', {
            method: 'POST', body: { community }
        }),
        onSuccess: addCommunity
    });

    const membersMutation = useMutation({
        mutationFn: invite => query('/users/@me/communities', {
            method: 'POST', body: { invite }
        }),
        onSuccess: addCommunity
    });

    function handleJoinTextChange(event) {
        const newJoinText = event.target.value;
        setJoinText(newJoinText);
    }

    function handleCreateTextChange(event) {
        const newCreateText = event.target.value;
        setCreateText(newCreateText);
    }

    function handleJoinCommunity() {
        const invite = { code: joinText };
        membersMutation.mutate(invite);
        closeModal();
        setJoinText('');
    }

    function handleCreateCommunity() {
        const community = { name: createText };
        communitiesMutation.mutate(community);
        closeModal();
        setCreateText('');
    }

    function addCommunity(community) {
        user.setCommunities((oldCommunities = []) => {
            const communityIsInCache = oldCommunities
                .some(c => c.id === community.id);

            return communityIsInCache
                ? oldCommunities
                : [community, ...oldCommunities];
        });
        user.setSelectedCommunity(community);
    }

    return <div className='add-community'>
        <div className='add-community__section'>
            <div className='add-community__label'>
                <span>Join</span> an existing community...
            </div>
            <div className='add-community__controls'>
                <Input
                    placeholder='Enter a community invite...'
                    maxLength='8'
                    value={joinText}
                    autoFocus={true}
                    disabled={createText}
                    onChange={handleJoinTextChange}
                />
                <Button
                    content='Join'
                    disabled={!joinText}
                    onClick={handleJoinCommunity}
                />
            </div>
        </div>
        <div className='add-community__section'>
            <div className='add-community__label'>
                ...or <span>create</span> a new one!
            </div>
            <div className='add-community__controls'>
                <Input
                    placeholder="Enter your new community's name..."
                    maxLength='40'
                    value={createText}
                    autoFocus={true}
                    disabled={joinText}
                    onChange={handleCreateTextChange}
                />
                <Button
                    content='Create'
                    disabled={!createText}
                    onClick={handleCreateCommunity}
                />
            </div>
        </div>
    </div>
}

export default AddCommunity;