import '../styles/Overview.css';
import { motion } from 'framer-motion';
import getIsTouchOnlyDevice from '../utils/getIsTouchOnlyDevice';
import { useUser } from './../contexts/UserProvider';
import { useModal } from './../contexts/ModalProvider';
import UserAvatar from './UserAvatar';
import Block from './Block';
import Button from './Button';

function Overview() {
    const user = useUser();
    const { openModal } = useModal();
    const isTouchOnlyDevice = getIsTouchOnlyDevice();
    const isNotificationGranted = Notification.permission === 'granted';

    function handleAddCommunity() {
        openModal('ADD_COMMUNITY');
    }

    function handleSettingsLinkClick(tabId) {
        user.setSelectedSection(2);
        user.setSelectedTabId(tabId);
    }

    async function handleEnableNotifications() {
        await Notification.requestPermission();
        user.savePushSubscription();
    }

    return <motion.div
        className='overview'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.4 }}
    >
        <motion.div
            className='overview__welcome'
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.4, delay: 0.2, type: 'spring' }}
        >
            <div className='overview__avatar'>
                <UserAvatar user={user} />
            </div>
            <h1 className='overview__title'>
                Welcome <span style={{ color: user.color }}>
                    {user.name}
                </span>
            </h1>
        </motion.div>
        <Block label='A Few Words'>
            <div className='overview__row'>
                <div className='overview__paragraphs'>
                    <p>
                        If you just got here, thank you for taking the time
                        to sign up and log in.
                    </p>
                    <p>
                        Scroll down, and you'll see some information that
                        will help you get started.
                    </p>
                    {isTouchOnlyDevice && <p>
                        If you're too excited for that (like us),
                        you can swipe to either side of the screen
                        to open up the left- and right-side panels.
                    </p>}
                    <p>
                        You can also join or create a community
                        right away, right here.
                    </p>
                </div>
                <div className='overview__buttons'>
                    <Button
                        content='Add Community'
                        onClick={handleAddCommunity}
                    />
                </div>
            </div>
        </Block>
        <Block label='Explore'>
            <div className='overview__row'>
                <div className='overview__paragraphs'>
                    <p>
                        Check out all the different ways you can set up both
                        your profile and app's appearance in the Settings.
                        You might get a cookie, but no promises...
                    </p>
                </div>
                <div className='overview__buttons'>
                    <Button
                        content='Profile'
                        onClick={() => handleSettingsLinkClick(0)}
                    />
                    <Button
                        content='Appearance'
                        onClick={() => handleSettingsLinkClick(3)}
                    />
                </div>
            </div>
        </Block>
        <Block label='Notifications'>
            <div className='overview__row'>
                <div className='overview__paragraphs'>
                    <p>
                        To keep up with your friends and communities, it's
                        highly recommended that you turn notifications on.
                        You can always turn them off in the Settings later.
                    </p>
                </div>
                <div className='overview__buttons'>
                    <Button
                        content='Enable Notifications'
                        disabled={isNotificationGranted}
                        onClick={handleEnableNotifications}
                    />
                </div>
            </div>
        </Block>
        <Block label='Install App'>
            <div className='overview__row'>
                <div className='overview__paragraphs'>
                    <p>
                        Sometimes you might want to easily access the app
                        without the hassle of typing in the address bar.
                        Worry no more, because this is how you can do it.
                    </p>
                    <p>
                        But first, let me take a sel- <span>ahem</span>,
                        let me enable the button first.
                    </p>
                </div>
                <div className='overview__buttons'>
                    <Button
                        content='Install App'
                        disabled={true}
                    />
                </div>
            </div>
        </Block>
    </motion.div>
}

export default Overview;